import { useTranslation } from 'react-i18next';
import logo from '../images/headerLogo.svg';
import './header.css';

function Header() {
  const { t } = useTranslation();
  return (
    <div className="header">
      <div className='container'>
        <h1>{t('BEST_REAL_ESTATE_COMMISSION_CALCULATOR')}</h1>
        <p>
          Powered by
          <a href='https://nimblechapps.com/' target='_blank'>
            <img src={logo} className="logo" alt="Nimblechapps logo" title="Best App Development company - Nimblechapps" />
          </a>
        </p>
      </div>
    </div>
  );
}

export default Header;
