import logo from '../images/nimbleLogo.svg';
import './footer.css';

function Footer() {
  return (
    <div className="footer">
      <div className='foterInline container'>
        <div className='footerLeft'>
          <p>Mothership</p>
          <a href='https://nimblechapps.com/' target='_blank'>
            <img src={logo} className="logo" alt="Nimblechapps Logo" title="Best App Development company - Nimblechapps" />
          </a>
        </div>
        <div className='footerRight'>
          <a href='https://www.facebook.com/nimblechapps' target="_blank"><i className='icon-facebook'></i></a>
          <a href='https://www.instagram.com/nimblechapps' target="_blank"><i className='icon-instagram'></i></a>
          <a href='https://www.linkedin.com/company/nimblechapps/?originalSubdomain=in' target="_blank"> <i className='icon-linkedin'></i></a>
          <a href='https://api.whatsapp.com/send?phone=919537158416' target="_blank"><i className='icon-whatsapp'></i></a>
          <a href="skype:nimblechapps?chat" target="_blank" data-rr-ui-event-key="skype:nimblechapps?chat" class="nav-link active"><i class="icon icon-skype"></i></a>
          <a href='https://twitter.com/i/flow/login?redirect_after_login=%2Fnimblechapps' target="_blank"><i className='icon-logo-1'></i></a>
          <a href='https://www.youtube.com/c/Nimblechapps' target="_blank"><i className='icon-Youtube'></i></a>
          <a href='https://www.pinterest.com/nimblechapps/' target="_blank"><i className='icon-Pinterest'></i></a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
