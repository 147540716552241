import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Header from './header/header';
import Footer from './footer/footer';
import FlatRate from './flatRate/flatRate';
import PercentageBased from './percentageBased/percentageBased';
import harcourts from './images/harcourts.svg';
import raywhite from './images/raywhite.webp';
import bayleys from './images/bayleys.png';
import star from './images/star.svg';
import Estateharcourts from './images/Bayleys-Estate-harcourts.svg';
import appstore from './images/appstore.svg';
import googleplay from './images/google-play-logo.svg';
import './App.css';

function App() {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);

  const isEmailValid = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    if (newEmail === '') {
      setErrorMessage('');
    } else if (!isEmailValid(newEmail)) {
      setErrorMessage('Please enter a valid email address.');
    } else {
      setErrorMessage('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isEmailValid(email)) {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email }),
      };

      // setIsToastShow(true)
      await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/sendEmail`, options)
        .then((res) => res.json())
        .then((data) => {
          setToastMessage(data?.message || `Your inquiry has been sent successfully.`)
          // console.log("res ==>", data);
          setToastStatus('Success')
          setIsToastShow(true)
        })
        .catch((err) => {
          // console.log("err ==>", err);
          setToastMessage(err.message)
          setToastStatus('Success')
          setIsToastShow(true)
        })
      setTimeout(() => {
        setIsToastShow(false)
      }, 2000)
      setEmail('');
      setErrorMessage('');
    } else {
      setErrorMessage('Please enter a email address.');
    }
  };

  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const [startDate, setStartDate] = useState(new Date());
  const [periodOfAgency, setPeriodOfAgency] = useState(90);
  const [endDate, setEndDate] = useState(null);
  const [isToastShow, setIsToastShow] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [toastStatus, setToastStatus] = useState('Success')

  useEffect(() => {
    const calculateEndDate = () => {
      const newEndDate = new Date(startDate);
      newEndDate.setDate(newEndDate.getDate() + periodOfAgency);
      return newEndDate;
    };

    const newEndDate = calculateEndDate();
    setEndDate(newEndDate);
  }, [startDate, periodOfAgency]);

  function formatDateToCustomString(date) {
    const options = {
      day: 'numeric',   // Display the day of the month (e.g., 31)
      month: 'short',    // Display the full month name (e.g., December)
      year: 'numeric',  // Display the full year (e.g., 2015)
    };

    return date.toLocaleDateString(undefined, options);
  }

  const [sharedAppraisalPrice, setSharedAppraisalPrice] = useState(0);
  const [sharedAdminFee, setSharedAdminFee] = useState(0);

  return (
    <>

      {isToastShow &&
        <div className={toastStatus === 'Success' ? 'toastSuccess' : 'toastInfo'}>
          <p>{toastMessage}</p>
        </div>
      }
      <Header />
      <div className='container main'>
        <div className='contentCenter'>
          <div className='left'>
            <h2>{t("GET_YOUR_BUSINESS")}<span> 1</span><img src={star} className="star" alt="star" /></h2>
            <div className='form'>
              <form onSubmit={handleSubmit}>
                <input
                  placeholder={t('EMAIL_ADDRESS')}
                  type="email"
                  style={{ border: errorMessage ? '1px solid red' : 'transparent' }}
                  value={email}
                  onChange={handleEmailChange}
                />
                <button type="submit" disabled={errorMessage ? true : false} style={{ opacity: errorMessage ? 0.3 : 1, cursor: errorMessage ? 'not-allowed' : 'pointer' }}>{t('SUBMIT')}</button>
              </form>
              <p>{t('REACH_OUT')}</p>
            </div>
            <div className='ourClientsMain'>
              <h3>{t('OUR_CLIENTS')}</h3>
              <div className='OurClients'>
                <div className='harcourtsBox'>
                  <a href='https://harcourts.net/nz/office/pinnacle' target="_blank"><img src={harcourts} alt="Harcourts International" title='Harcourts International Commission Calculator' /></a>
                  <div className='harcourtscontent'>
                    <h2>{t('GET_THIS_APP_ON')}</h2>
                    <div className='google-play'>
                      <a className='google-img' href='https://play.google.com/store/apps/details?id=com.harcourts.harcourtscalculator' target='_blank'><img src={googleplay} alt="google play" title='google play' /></a>
                      <a href='https://apps.apple.com/in/app/harcourts-calculator/id6470010555' target="_blank"><img src={appstore} alt="app store" title='app store' /></a>
                    </div>
                  </div>
                </div>
                <div className='harcourtsBox'>
                  <a href='https://www.raywhite.com/' target="_blank"><img src={raywhite} alt="Harcourts International" title='Harcourts International Commission Calculator' /></a>
                  <div className='harcourtscontent'>
                    <h2>{t('GET_THIS_APP_ON')}</h2>
                    <div className='google-play'>
                      <a className='google-img' href='https://play.google.com/store/apps/details?id=com.raywhite' target='_blank'><img src={googleplay} alt="google play" title='google play' /></a>
                      <a href='https://apps.apple.com/pw/app/raywhite-commission-calculator/id6502817488' target="_blank"><img src={appstore} alt="app store" title='app store' /></a>
                    </div>
                  </div>
                </div>
                <div className='harcourtsBox'>
                  <a href='https://www.bayleys.co.nz/' target='_blank'><img src={bayleys} alt="Bayleys Real Estate" title='Bayleys Real Estate Commission Calculator' /></a>
                  <div className='harcourtscontent'>
                    <h2>{t('GET_THIS_APP_ON')}</h2>
                    <div className='google-play'>
                      <a href='https://play.google.com/store/apps/details?id=com.bayleys.calc1' target='_blank'><img src={googleplay} alt="google play" title='google play' /></a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='image-content'>
                <img src={Estateharcourts} alt="Bayleys Real Estate" title='Bayleys Real Estate' />
                <h6>{t('LEARN_HOW_NIMBLECHAPPS_ASSISTED')}
                  <a href='https://www.nimblechapps.com/blog/how-nimblechapps-transformed-the-commission-calculation-process-for-two-leading-real-estate-companies' target="_blank"> <strong>{t('READ_MORE')}</strong></a>
                </h6>
              </div>
            </div>
          </div>

          <div className='right'>
            <form action="">
              <h6>{t('90_DAY_CALCULATOR')}</h6>
              <div className='whiteBox'>
                <div className='box'>
                  <p>{t('START_DATE')}</p>
                  <div className='formGroup'>
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} dateFormat={'d MMM yyyy'} onKeyDown={(e) => {
                      e.preventDefault();
                    }} />
                  </div>
                </div>
                <div className='box'>
                  +
                </div>
                <div className='box'>
                  <p>{t('PERIOD_OF_AGENCY')}</p>
                  <div className='formGroupText'>
                    <input type="text" placeholder='1'
                      value={periodOfAgency || ''} // Display an empty string if periodOfAgency is falsy
                      maxLength="3" // Set the maximum length to 3 characters
                      onChange={(e) => {
                        const value = e.target.value;
                        setPeriodOfAgency(value === '' ? '' : parseInt(value));
                      }}
                    />
                  </div>
                </div>
                <div className='box'>
                  =
                </div>
                <div className='box'>
                  <p>{t('END_DATE')}</p>
                  <label>{endDate ? formatDateToCustomString(endDate) : ''}</label>
                </div>
              </div>
              <h6>{t('COMMISSION_CALCULATOR')}</h6>
              <div className="tabs">
                <button type='button' onClick={() => handleTabClick(0)} className={activeTab === 0 ? 'active' : ''}>
                  {t('PERCENTAGE_BASED')}
                </button>
                <button type='button' onClick={() => handleTabClick(1)} className={activeTab === 1 ? 'active' : ''}>
                  {t('FLAT_RATE')}
                </button>
              </div>
              <div className="tab-content">
                <div className="tab-pane" style={{ display: activeTab === 0 ? 'block' : 'none' }}>
                  <PercentageBased
                    sharedAppraisalPrice={sharedAppraisalPrice}
                    sharedAdminFee={sharedAdminFee}
                    setSharedAppraisalPrice={setSharedAppraisalPrice}
                    setSharedAdminFee={setSharedAdminFee}
                  />
                </div>
                <div
                  className="tab-pane"
                  style={{ display: activeTab === 1 ? 'block' : 'none' }}
                >
                  <FlatRate sharedAppraisalPrice={sharedAppraisalPrice}
                    sharedAdminFee={sharedAdminFee}
                    setSharedAppraisalPrice={setSharedAppraisalPrice}
                    setSharedAdminFee={setSharedAdminFee} />
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className='ourClientsIpad'>
          <h3>{t('OUR_CLIENTS')}</h3>
          <div className='OurClients'>
            <div className='harcourtsBox'>
              <a href='https://harcourts.net/nz/office/pinnacle' target="_blank"><img src={harcourts} alt="harcourts" title='harcourts' /></a>
              <div className='harcourtscontent'>
                <h2>{t('GET_THIS_APP_ON')}</h2>
                <div className='google-play'>
                  <a className='google-img' href='https://play.google.com/store/apps/details?id=com.harcourts.harcourtscalculator' target='_blank'><img src={googleplay} alt="google play" title='google play' /></a>
                  <a href='https://apps.apple.com/in/app/harcourts-calculator/id6470010555' target="_blank"><img src={appstore} alt="app store" title='app store' /></a>
                </div>
              </div>
            </div>
            <div className='harcourtsBox'>
              <a href='https://www.raywhite.com/' target="_blank"><img src={raywhite} alt="Harcourts International" title='Harcourts International Commission Calculator' /></a>
              <div className='harcourtscontent'>
                <h2>{t('GET_THIS_APP_ON')}</h2>
                <div className='google-play'>
                  <a className='google-img' href='https://play.google.com/store/apps/details?id=com.raywhite' target='_blank'><img src={googleplay} alt="google play" title='google play' /></a>
                  <a href='https://apps.apple.com/pw/app/raywhite-commission-calculator/id6502817488' target="_blank"><img src={appstore} alt="app store" title='app store' /></a>
                </div>
              </div>
            </div>
            <div className='harcourtsBox'>
              <a href='https://www.bayleys.co.nz/' target='_blank'><img src={bayleys} alt="bayleys" title='bayleys' /></a>
              <div className='harcourtscontent'>
                <h2>{t('GET_THIS_APP_ON')}</h2>
                <div className='google-play'>
                  <a href='https://play.google.com/store/apps/details?id=com.bayleys.calc1' target='_blank'><img src={googleplay} alt="google play" title='google play' /></a>
                </div>
              </div>
            </div>
          </div>
          <div className='image-content'>
            <img src={Estateharcourts} alt="Bayleys Real Estate" title='Bayleys Real Estate' />
            <h6>{t('LEARN_HOW_NIMBLECHAPPS_ASSISTED')}
              <a href='https://www.nimblechapps.com/blog/how-nimblechapps-transformed-the-commission-calculation-process-for-two-leading-real-estate-companies' target="_blank"> <strong>{t('READ_MORE')}</strong></a>
            </h6>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default App;


