import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../App.css';

function FlatRate({ sharedAppraisalPrice, sharedAdminFee, setSharedAppraisalPrice, setSharedAdminFee }) {
    const { t } = useTranslation();
    const [flateAppraisalprice, setFlateAppraisalprice] = useState(0);
    const [flatRate, setFlatRate] = useState(0);
    const [flatadminFee, setFlatadminFee] = useState(0);
    const [percentage, setPercentage] = useState(0);
    const [total, setTotal] = useState(0);
    const [isGSTIncluded, setIsGSTIncluded] = useState(false); // State to track GST selection

    useEffect(() => {
        const calculatedPercentage = isGSTIncluded ? ((flatRate / 115) * sharedAppraisalPrice) : ((flatRate / 100) * sharedAppraisalPrice);
        setPercentage(calculatedPercentage)
        const calculatedTotal = calculatedPercentage + sharedAdminFee;
        setTotal(calculatedTotal)
        const gstAmount = isGSTIncluded ? ((((flatRate / 100) * sharedAppraisalPrice) + sharedAdminFee) * 15 / 115) : (calculatedTotal * 15 / 100); // Assuming 15% GST rate
        const subtotal = isGSTIncluded ? (((flatRate / 100) * sharedAppraisalPrice) + sharedAdminFee) : (calculatedTotal + gstAmount);
    }, [sharedAppraisalPrice, flatRate, sharedAdminFee, isGSTIncluded])

    const handleAppraisalPriceChange = (e) => {
        const value = parseFloat(e.target.value.replace(/,/g, ''));
        setFlateAppraisalprice(value);
        setSharedAppraisalPrice(value);
    };

    const handleFlatRateChange = (e) => {
        const value = e.target.value;
        const parsedValue = (value) || 0;
        setFlatRate(parsedValue);
    };

    // const handleFlatRateChange = (e) => {
    //     const value = parseFloat(e.target.value.replace(/,/g, ''));
    //     setFlatRate(value);
    // };

    const handleFlateAdminFeeChange = (e) => {
        const value = parseFloat(e.target.value.replace(/,/g, ''));
        setFlatadminFee(value);
        setSharedAdminFee(value);
    };

    const handleGSTChange = (e) => {
        setIsGSTIncluded(e.target.value === 'gstinc');
    };

    // Calculate subtotal based on selected GST option
    const gstAmount = isGSTIncluded ? ((((flatRate / 100) * sharedAppraisalPrice) + sharedAdminFee) * 15 / 115) : (total * 15 / 100); // Assuming 15% GST rate
    const subtotal = isGSTIncluded ? (((flatRate / 100) * sharedAppraisalPrice) + sharedAdminFee) : (total + gstAmount);

    return (
        <>
            <div className='tabOneBoxTop'>
                <div className='formfield'>
                    <div className='inputBox'>
                        <label className='labelText'>{t('APPRAISAL_PRICE')}</label>
                        <input type="text" name="sharedAppraisalPrice " placeholder='10,000' onChange={handleAppraisalPriceChange} value={sharedAppraisalPrice ? sharedAppraisalPrice.toLocaleString('en') : null} />
                        <i className='icon icon-doller' />
                    </div>
                    <div className='inputBox'>
                        <label className='labelText'>{t('ADMIN_FEE')}</label>
                        <input type="text" name="adminfee " placeholder='500' value={sharedAdminFee ? sharedAdminFee.toLocaleString('en') : null}
                            onChange={handleFlateAdminFeeChange} />
                        <i className='icon icon-doller' />
                    </div>
                </div>
                <div className='formfield'>
                    <div className='inputBox'>
                        <label className='labelText'>{t('FLAT_RATE')}</label>
                        <input type="text" name="flatrate " placeholder='8' onChange={handleFlatRateChange} value={flatRate ? flatRate.toLocaleString('en') : null} />
                        <i className='icon icon-percentage' />
                    </div>
                    <div className='formRadioMain'>
                        <label className='labelText'>{t('GST_NEW')}</label>
                        <div className='formRadio'>
                            <div className='radio'>
                                <input
                                    type="radio"
                                    id="gst"
                                    name="gst"
                                    value="gst"
                                    onChange={handleGSTChange}
                                    checked={!isGSTIncluded}
                                />
                                <label htmlFor="gst">{t('+GST')}</label>
                            </div>
                            <div className='radio'>
                                <input
                                    type="radio"
                                    id="gstinc"
                                    name="gst"
                                    value="gstinc"
                                    onChange={handleGSTChange}
                                    checked={isGSTIncluded}
                                />
                                <label htmlFor="gstinc">{t('GST_INCLUDED')}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='tabBoxBottom'>
                <div className='repeateBox'>
                    <div className='leftPart'>
                        <p className='labelLeft'>{t('COMMISSION')} <strong>$ {percentage ? percentage?.toFixed(2) : 0}</strong> <span>+</span> {t('ADMIN_FEE')} <strong>$ {sharedAdminFee ? sharedAdminFee?.toFixed(2) : 0}</strong> <span>=</span> </p>
                    </div>
                    <div className='rightPart'>
                        <p class="labelLeft">{t('SUB_TOTAL')} <strong>$ {total ? total?.toFixed(2) : 0}</strong></p>
                    </div>
                </div>
                <div className='repeateBox'>
                    <div className='leftPart'>
                        <p className='labelLeft'>{t('SUB_TOTAL')} <strong>$ {total ? total?.toFixed(2) : 0}</strong> <span>+</span>{t('GST')} <strong>$ {gstAmount ? gstAmount?.toFixed(2) : 0}</strong> <span>=</span> </p>
                    </div>
                    <div className='rightPart'>
                        <p class="labelLeft">{t('TOTAL')}<strong> $ {subtotal ? subtotal?.toFixed(2) : 0}</strong></p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FlatRate;