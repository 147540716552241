import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import '../App.css';

function PercentageBased({ sharedAppraisalPrice, sharedAdminFee, setSharedAppraisalPrice, setSharedAdminFee }) {
    const { t } = useTranslation();
    const [commission, setCommission] = useState(0);
    // console.log("commission ---> ", commission);
    const [propertyPrice, setPropertyPrice] = useState(0);
    // console.log("propertyPrice --->", propertyPrice);
    const [adminFee, setAdminFee] = useState(0);
    const [appraisalPrice, setAppraisalPrice] = useState(0);
    const [extraComissionPercentage, setExtraComissionPercentage] = useState(0);
    // console.log("extraComissionPercentage ---> ", extraComissionPercentage);
    const [subTotalPrice, setSubTotalPrice] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [commissionPrice, setCommissionPrice] = useState(0);
    // console.log("commissionPrice --->", commissionPrice);

    const handleCommissionChange = (event) => {
        const commissionValue = (event.target.value);
        setCommission(commissionValue || 0);
    };

    const handlePropertyPriceChange = (event) => {
        const propertyPriceValue = parseFloat(event.target.value.replace(/,/g, ''));
        setPropertyPrice(propertyPriceValue || 0);
    };
    const handleAdminFeeChange = (event) => {
        const adminFeeValue = parseFloat(event.target.value.replace(/,/g, ''));
        setAdminFee(adminFeeValue || 0);
        setSharedAdminFee(adminFeeValue || 0);
    };
    const handleExtraCommissionPerChange = (event) => {
        const extraComissionPercentage = (event.target.value);
        setExtraComissionPercentage(extraComissionPercentage || 0);
    };
    const appraisalPriceChange = (event) => {
        const appraisalPriceValue = parseFloat(event.target.value.replace(/,/g, ''));
        setAppraisalPrice(appraisalPriceValue || 0);
        setSharedAppraisalPrice(appraisalPriceValue || 0)
    };

    useEffect(() => {
        if ((propertyPrice === 0 && extraComissionPercentage === 0) || (propertyPrice === 0 && extraComissionPercentage > 0) || (propertyPrice > 0 && extraComissionPercentage === 0) && commission > 0) {
            let commissionPrice = sharedAppraisalPrice * (commission / 100)
            setCommissionPrice(commissionPrice)
            setTotalPrice(sharedAdminFee + commissionPrice)
            setSubTotalPrice(sharedAdminFee + commissionPrice)
        } else if (propertyPrice > 0) {
            if (sharedAppraisalPrice > propertyPrice) {
                let commissionPrice = propertyPrice * (commission / 100) + (sharedAppraisalPrice - propertyPrice) * (extraComissionPercentage / 100)
                setCommissionPrice(commissionPrice)
                setTotalPrice(commissionPrice + sharedAdminFee)
                setSubTotalPrice(commissionPrice + sharedAdminFee)
            } else {
                let commissionPrice = sharedAppraisalPrice * (commission / 100)
                setCommissionPrice(commissionPrice)
                setTotalPrice(commissionPrice + sharedAdminFee)
                setSubTotalPrice(commissionPrice + sharedAdminFee)
            }
        }
        else {
            let commissionPrice = sharedAppraisalPrice * (commission / 100)
            setCommissionPrice(commissionPrice)
            setTotalPrice(sharedAdminFee)
            setSubTotalPrice(sharedAdminFee)
        }

    }, [commission, propertyPrice, sharedAdminFee, sharedAppraisalPrice, extraComissionPercentage])

    return (
        <>
            <div className='tabOneBoxTop'>
                <div className='formfield'>
                    <div className='inputBox'>
                        <label className='labelText'>{t('APPRAISAL_PRICE')}</label>
                        <input type="text" name="appraisalprice " placeholder='10,00,000' value={sharedAppraisalPrice ? sharedAppraisalPrice.toLocaleString('en') : null}
                            onChange={appraisalPriceChange} />
                        <i className='icon icon-doller' />
                    </div>
                    <div className='inputBox'>
                        <label className='labelText'>{t('ADMIN_FEE')}</label>
                        <input type="text" name="adminfee" value={sharedAdminFee ? sharedAdminFee.toLocaleString('en') : null}
                            onChange={handleAdminFeeChange} placeholder='750' />
                        <i className='icon icon-doller' />
                    </div>
                </div>
                <div className='formfield'>
                    <div className='inputBox'>
                        <label className='labelText'>{t('COMMISSION_PERCENTAGE')}</label>
                        <input type="text" name="commissioncalculator" value={commission ? commission.toLocaleString('en') : null}
                            onChange={handleCommissionChange} placeholder='10' />
                        <i className='icon icon-percentage' />
                    </div>
                    <div className='inputBox'>
                        <label className='labelText'>{t('ON_FIRST_OF_PROPERTY_PRICE')}</label>
                        <input type="text" name="onfirstpropertyprice" value={propertyPrice ? propertyPrice.toLocaleString('en') : null}
                            onChange={handlePropertyPriceChange} placeholder='50,00,000' />
                        <i className='icon icon-doller' />
                    </div>
                </div>
                <div className='formfield'>
                    <div className='inputBox'>
                        <label className='labelText'>{t('THERE_AFTER_COMMISSION_PERCENTAGE')}</label>
                        <input type="text" name="aftercommissionpercentage" placeholder='5' value={extraComissionPercentage ? extraComissionPercentage.toLocaleString('en') : null} onChange={handleExtraCommissionPerChange} />
                        <i className='icon icon-percentage' />
                    </div>
                </div>
            </div>
            <div className='tabBoxBottom'>
                <div className='repeateBox'>
                    <div className='leftPart'>
                        <p className='labelLeft'>{t('COMMISSION')} <strong>$ {commissionPrice ? commissionPrice?.toFixed(2) : 0}</strong> <span>+</span> {t('ADMIN_FEE')} <strong>$ {sharedAdminFee ? sharedAdminFee?.toFixed(2) : 0}</strong> <span>=</span> </p>
                    </div>
                    <div className='rightPart'>
                        <p className="labelLeft">{t('SUB_TOTAL')} <strong>$ {subTotalPrice ? subTotalPrice?.toFixed(2) : 0}</strong></p>
                    </div>
                </div>
                <div className='repeateBox'>
                    <div className='leftPart'>
                        <p className='labelLeft'>{t('SUB_TOTAL')} <strong>$ {subTotalPrice ? subTotalPrice?.toFixed(2) : 0}</strong> <span>+</span>{t('GST')} <strong>$ {(subTotalPrice * 0.15) ? (subTotalPrice * 0.15)?.toFixed(2) : 0}</strong> <span>=</span> </p>
                    </div>
                    <div className='rightPart'>
                        <p className="labelLeft">{t('TOTAL')}<strong> $ {(totalPrice + totalPrice * 0.15) ? (totalPrice + totalPrice * 0.15)?.toFixed(2) : 0}</strong></p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PercentageBased;